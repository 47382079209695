<template>
  <div class="d-flex flex-column gap-3 mt-4">
    <div v-if="template.indicators.length == 0"> <b-icon-exclamation-triangle-fill /> Nenhum Indicador cadastrado para esse sub-módulo</div>
    <b-card v-for="(indicator, index) in template.indicators" :key="index">
      <section
        v-if="indicator.indicator"
        class="d-md-flex align-items-center justify-content-between "
      >
        <strong>
          {{ indicator.indicator.name }}
        </strong>
        <span v-if="indicator.score">
          Nota: <strong>{{ indicator.score.toFixed(1) }}</strong>
        </span>
      </section>

      <section
        class="d-md-flex align-items-center mt-2 mb-4 justify-content-between w-100 gap-4"
      >
        <div>
          <span class="mr-2">Valor coletado: </span>
          <span>
            {{ formatValue(indicator.collected_value, indicator.type) }}
          </span>
        </div>
        <div>
          <span class="mr-2">Validação: </span>
          <span>
            {{ indicator.type === "manual" ? "Manual" : "Sistêmica" }}
          </span>
        </div>
      </section>
      <range-validation
        class="mt-2"
        :note="indicator.score"
        :tracks="indicator.ranges"
        :type="indicator.type"
        :indicator_id="indicator.indicator.id"
        @update_indicators="$emit('update_indicators')"
      />
    </b-card>
  </div>
</template>

<script>
import RangeValidation from "./RangeValidation";

export default {
  components: {
    RangeValidation,
  },
  props: {
    template: Object,
  },
  methods: {
    formatValue(value, type) {
      if (value < 0) {
        return "Valor não coletado";
      }

      const val = this.$options.filters.number(value);

      switch (type) {
        case "percentage":
          return `${val}%`;
        case "money":
          return `R$ ${String(val).padEnd(3, ",0")}`;
        case "value":
          return String(val).replace(",", ".");
        case "yes_no":
          return val;
        case "include":
          return val;
        case "manual":
          return val;
      }
    },
  },
};
</script>
